<template>
    <div>
        <b-modal :id="modalId" modal-class="modal-w-910" hide-header hide-footer @hide="() => {if (!requestModalOpened) $emit('modalClosed')}">
            <div class="customize-dashboard-modal">
                <div class="customize-dashboard-modal__side">
                    <header class="customize-dashboard-modal__header">
                        <h5 class="heading5">Add Widgets</h5>
                        <a href="#" @click.prevent="close()" class="close"></a>
                    </header>

                    <p>Drag widgets into your desired location to add to homescreen</p>

                    <ul class="list-widgets">
                        <li
                            v-for="widget in notUsedWidgets"
                            :key="widget.id"
                            :data-widget="widget.id"
                            @drag="drag"
                            @dragend="dragend"
                            draggable="true"
                        >
                            {{ widget.name }}
                        </li>
                    </ul>

                    <template v-if="premiumCharts.length">

                        <div class="widget-heading">
                            <span class="widget-heading__title">Premium Charts</span>

                            <a class="widget-heading__unlock" @click="showUnlockChartsModal('premium')">Unlock</a>
                        </div>

                        <ul class="list-widgets">
                            <li v-for="(chart, index) in premiumCharts" :key="index">{{chart.name}}</li>
                        </ul>

                    </template>

                    <template v-if="!hasCustomCharts">
                        <div class="widget-heading widget-heading__custom">
                            <span class="widget-heading__title">Custom Charts</span>
                            <a class="widget-heading__unlock" @click="showUnlockChartsModal('custom')">Unlock</a>

                        </div>

                        <ul class="list-charts">
                            <li>Unlock all premium charts</li>
                            <li>Customize chart types for any chart</li>
                            <li>Create up to 5 custom charts</li>
                        </ul>
                    </template>
                </div>
                <div class="customize-dashboard-modal__main">
                    <h5 class="modal-title">Customize Dashboard</h5>

                    <ul class="list-action-hint">
                        <li class="list-action-hint__reorder">Drag to reorder</li>
                        <li class="list-action-hint__remove">Remove</li>
                        <li class="list-action-hint__resize">Drag corner to resize</li>
                    </ul>

                    <div id="content">
                        <div class="vue-grid-layout-wrapper">
                            <grid-layout ref="gridlayout" :layout.sync="layout"
                                         :col-num="12"
                                         :row-height="30"
                                         :is-draggable="true"
                                         :is-resizable="true"
                                         :vertical-compact="true"
                                         :use-css-transforms="true">
                                <grid-item :key="item.i" v-for="item in layout"
                                           class="card"
                                           :x="item.x"
                                           :y="item.y"
                                           :w="item.w"
                                           :h="item.h"
                                           :i="item.i"
                                           :minW="item.minW"
                                           :maxW="item.maxW"
                                           :minH="item.minH"
                                           :maxH="item.maxH"
                                           @moved="saveSettings()"
                                           @resized="saveSettings()">
                                    <a href="#" class="card__remove" @click.prevent="removeItem(item.i)"></a>
                                    <header class="card__header">
                                        {{ item.name }}
                                    </header>
                                    <div class="card__inner">
                                        <img :src="item.img" style="height: 95px;" alt="img"/>
                                    </div>
                                </grid-item>
                            </grid-layout>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal id="unlockCharts" title="Request Access" hide-footer @hide="requestModalOpened = false; $bvModal.show(modalId)">
            <p class="text-center">We will notify your administrator that you would like to use {{stringHelper.ucFirst(unlockChartsType)}} Charts</p>

            <footer class="modal-footer">
                <button class="button" @click="sendRequest" :disabled="loading">
                    <span v-if="!loading">Send Request</span>
                    <span v-else><b-spinner class="mr-1" small></b-spinner>Sending Request...</span>
                </button>
            </footer>
        </b-modal>
    </div>
</template>


<script>
    import VueGridLayout from 'vue-grid-layout'
    import dashboard from '@/services/dashboard.service'
    import enterprise from '@/services/enterprise.service'
    import {mapGetters, mapMutations} from "vuex"
    import Vue from "vue";

    let mouseXY = {"x": null, "y": null}
    let DragPos = {"x": null, "y": null, "w": 4, "h": 4, "i": null, "minW": 4, "maxW": 12, "minH": 4, "maxH": 4}

    export default {
        name: "CustomizeDashboard",
        components: {
            GridLayout: VueGridLayout.GridLayout,
            GridItem: VueGridLayout.GridItem
        },
        data() {
            return {
                modalId: "customize-dashboard-modal",
                widgets: [],
                premiumCharts: [],
                hasCustomCharts: false,

                unlockChartsType: null,
                loading: false,
                requestModalOpened: false,
            }
        },
        mounted() {
            const handler = (e) => {
                mouseXY.x = e.clientX
                mouseXY.y = e.clientY
            }
            window.addEventListener('dragover', handler, false)
            this.$once('hook:beforeDestroy',
                () => window.removeEventListener('dragover', handler, false)
            )
        },
        computed: {
            ...mapGetters({
                layout: 'dashboard/layout',
            }),
            notUsedWidgets() {
                return this.widgets.filter(widget => {
                    return !widget.used
                })
            },
        },
        methods: {
            ...mapMutations({
                addChart: 'dashboard/addChart',
                changeLayout: 'dashboard/changeLayout',
            }),
            getAvailableCharts() {
                dashboard.getAvailableCharts().then(response => {
                    // retrieve all and mark as used that already in layout
                    this.widgets = response.data.available.map(widget => ({...widget, used: this.layout.some(item => {
                            return item.i === widget.id
                        })
                    }))

                    this.premiumCharts = response.data.premium
                    this.hasCustomCharts = response.data.has_custom
                })
            },
            saveSettings() {
                enterprise.saveDashboardSettings(this.layout)
            },
            open() {
                this.getAvailableCharts()
                this.$bvModal.show(this.modalId)
            },
            close() {
                this.$bvModal.hide(this.modalId)
            },
            drag: function () {
                let parentRect = document.getElementById('content').getBoundingClientRect()
                let mouseInGrid = false
                if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
                    mouseInGrid = true
                }

                if (mouseInGrid === true && (this.layout.findIndex(item => item.i === 'drop')) === -1) {
                    this.addChart({
                        x: (this.layout.length * 2) % (this.colNum || 12),
                        y: this.layout.length + (this.colNum || 12), // puts it at the bottom
                        w: 4,
                        h: 4,
                        i: 'drop',
                        minW: 4,
                        maxW: 12,
                        minH: 4,
                        maxH: 4
                    })
                }
                let index = this.layout.findIndex(item => item.i === 'drop')
                if (index !== -1) {
                    try {
                        this.$refs.gridlayout.$children[this.layout.length].$refs.item.style.display = "none"
                    } catch {
                        console.log('error drag')
                    }
                    let el = this.$refs.gridlayout.$children[index]
                    el.dragging = {"top": mouseXY.y - parentRect.top, "left": mouseXY.x - parentRect.left}
                    let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left)
                    if (mouseInGrid === true) {
                        this.$refs.gridlayout.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, 4, 4)
                        DragPos.i = String(index)
                        DragPos.x = this.layout[index].x
                        DragPos.y = this.layout[index].y
                    }
                    if (mouseInGrid === false) {
                        this.$refs.gridlayout.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, 4, 4)
                        this.changeLayout(this.layout.filter(obj => obj.i !== 'drop'))
                    }
                }
            },
            dragend: function (event) {
                let parentRect = document.getElementById('content').getBoundingClientRect()
                let mouseInGrid = false
                if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
                    mouseInGrid = true
                }
                if (mouseInGrid === true) {
                    this.$refs.gridlayout.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 4, 4)
                    this.changeLayout(this.layout.filter(obj => obj.i !== 'drop'))

                    let widgetId = event.target.getAttribute("data-widget")
                    this.markWidget(widgetId)
                    let widgetData = this.getWidgetData(widgetId)

                    this.addChart({
                        x: DragPos.x,
                        y: DragPos.y,
                        w: 4,
                        h: 4,
                        i: widgetData.id,
                        minW: 4,
                        maxW: 12,
                        minH: 4,
                        maxH: 4,
                        name: widgetData.name,
                        img: widgetData.img,
                        src: widgetData.src,
                    })
                    this.$refs.gridlayout.dragEvent('dragend', DragPos.i, DragPos.x, DragPos.y, 4, 4)
                    try {
                        this.$refs.gridlayout.$children[this.layout.length].$refs.item.style.display = "block"

                        this.saveSettings()
                    } catch {
                        console.log('error dragend')
                    }
                }
            },
            getWidgetData(id) {
                const index = this.widgets.map(widget => widget.id).indexOf(parseInt(id))
                if (index >= 0) {
                    return this.widgets[index]
                }
            },
            markWidget(id, mark = true) {
                const index = this.widgets.map(widget => widget.id).indexOf(parseInt(id))
                if (index >= 0) {
                    this.widgets[index].used = mark
                }
            },
            removeItem(val) {
                const index = this.layout.map(item => item.i).indexOf(val)
                this.layout.splice(index, 1)
                this.markWidget(val, false)

                this.saveSettings()
            },
            showUnlockChartsModal(chartType) {
                this.unlockChartsType = chartType;
                this.requestModalOpened = true
                this.$bvModal.hide(this.modalId)
                this.$bvModal.show('unlockCharts')
            },
            sendRequest() {
                this.loading = true

                dashboard.sendChartsRequest(this.unlockChartsType).then((response) => {
                    this.loading = false

                    if (response.data) {
                        this.$bvModal.hide('unlockCharts')

                        Vue.notify({
                            group: 'app',
                            type: 'success',
                            duration: 2000,
                            text: "Request Sent"
                        })
                    }
                    else {
                        Vue.notify({
                            group: 'app',
                            type: 'error',
                            duration: 2000,
                            text: "Error occurred while sending request"
                        })
                    }
                })
            }
        }
    }
</script>
